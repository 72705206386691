import React, {Component} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import {withStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import Autocomplete, {createFilterOptions} from '@material-ui/lab/Autocomplete';
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import ArchiveRoundedIcon from '@material-ui/icons/ArchiveRounded';
import GridItem from "components/Grid/GridItem.js";
import OptionWithOther from "components/OptionWithOther/OptionWithOther.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import FormControl from "@material-ui/core/FormControl";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import InputLabel from "@material-ui/core/InputLabel";
import {dangerColor, infoColor, successColor} from "assets/jss/material-dashboard-react.js";
// core components
import tableStyles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";
import {Dialog, DialogActions, DialogContent, DialogTitle, Divider} from "@material-ui/core";
import DialogContentText from "@material-ui/core/DialogContentText";
import {Add, ArrowForward, OpenInBrowser} from "@material-ui/icons";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {enGB} from "date-fns/locale";
import {parseDate} from "../../util/DateUtils";
import ChipInput from "material-ui-chip-input";
import FormHelperText from "@material-ui/core/FormHelperText";
import {format} from "date-fns";

const validation = {}

const guestValidation = {
    'fullName': value => (value.length === 0 && 'Name must be set') || '',
    'contactNumbers': value => (value.length === 0 && 'Contact number must be set') || (!value.match(/^0[1237][0-9]{9}$/) && 'Invalid number') || '',
    'postcode': value => (value.length === 0 && 'Postcode must be set') || (!value.match(/^[A-Z]{1,2}[0-9][0-9A-Z]? ?[0-9][A-Z]{2}$/) && 'Invalid postcode') || '',
    'address':  value => (value.length === 0 && 'Address must be set') || '',
    'numberOfPeople':  value => (value.length === 0 && 'Number of people must be set') || (parseInt(value) < 1 && 'Value must be at least 1') || ''
}
const GUEST_FIELDS = ["fullName", "contactNumbers", "postcode", "address", "disabled", "disabledInfo", "accessibilityIssues",
    "gender", "numberOfPeople", "ethnicityCore", "ethnicityOther", "dob", "ages", "faithCore", "faithOther",
    "dietaryRequirementsCore", "dietaryRequirementsOther", "languagesSpokenCore", "languagesSpokenOther"];

const GUEST_REFERRAL_DIFFERENCE_WARNING = "Value on guest differs to value on referral"
const styles = (theme) => ({
})
class ReferralsTable extends Component {
    constructor(props) {
        super(props);
        this.getReferralActions = this.getReferralActions.bind(this);
        this.getGuestSelector = this.getGuestSelector.bind(this);
        this.getModeOfDeliverySelector = this.getModeOfDeliverySelector.bind(this);
        this.getCashPilotStatusSelector = this.getCashPilotStatusSelector.bind(this);
        this.getCollectionHubSelector = this.getCollectionHubSelector.bind(this);
        this.setOpen = this.setOpen.bind(this)
        this.setEdit = this.setEdit.bind(this)
        this.editReferral = this.editReferral.bind(this)
        this.changeValue = this.changeValue.bind(this)
        this.confirmArchive = this.confirmArchive.bind(this);
        this.confirmSwitchToReadyMealReferral = this.confirmSwitchToReadyMealReferral.bind(this);
        this.changeGuestValue = this.changeGuestValue.bind(this);
        this.changeGuestValueByIdx = this.changeGuestValueByIdx.bind(this);
        this.deleteGuestValueByIdx = this.deleteGuestValueByIdx.bind(this);
        this.addGuestValueToList = this.addGuestValueToList.bind(this);
        this.addNewReferralAgency = this.addNewReferralAgency.bind(this);
        this.setGuest = this.setGuest.bind(this)
        this.editGuest = this.editGuest.bind(this)

        this.state = {
            open: {},
            edit: {},
            errors: {},
            confirmArchiveReferral: null,
            editGuest: null,
            editGuestReferralId: null,
            guestErrors: null,
            guestReferralDifferences: {},
            displayGuestMessage: false
        };
    }

    setOpen(key) {
        const edit = this.state.edit;
        const errors = this.state.errors;
        const open = this.state.open;
        if (!open[key]) {
            this.props.fetchReferral(key).then(() => {
                open[key] = true;
                this.setState({open: open})
            });
        }
        else {
            delete edit[key];
            delete errors[key];
            open[key] = false;
            this.setState({open: open, edit: edit, errors: errors});
        }
    }

    changeValue(id, field, value) {
        var edit = this.state.edit;
        var errors = this.state.errors;
        edit[id][field] = value;
        errors[id][field] = (validation[field] && validation[field](value)) || ''
        this.setState({edit: edit, errors: errors});
    }

    editReferral(key) {
        this.props.editReferral(this.state.edit[key]);
        delete this.state.edit[key];
        delete this.state.errors[key];
    }

    addNewReferralAgency() {
        this.props.addOption(this.state.newReferralAgency, 'REFERRAL_AGENCY')
          .then(() => this.setState({ newReferralAgency: null, newReferralAgencyDialog: false }))
    }

    setEdit(key) {
        const edit = this.state.edit;
        const errors = this.state.errors;
        const open = this.state.open;

        if (!open[key]) {
            this.props.fetchReferral(key).then(() => {
                open[key] = true;
                edit[key] = Object.assign({}, this.props.referrals[key].referral);
                errors[key] = {};
                this.setState({edit: edit, open: open, errors: errors})
            });
        }
        else {
            if (!edit[key]) {
                edit[key] = Object.assign({}, this.props.referrals[key].referral);
                errors[key] = {};
            }
            else {
                delete edit[key];
                delete errors[key]
            }
            this.setState({edit: edit, errors: errors});
        }
    }

    setGuest(referralId, guestId) {
        this.props.setGuest(referralId, guestId)
            .then(() => {
                let referral = this.props.referrals[referralId].referral;
                let guest = referral.guest;
                this.setState({
                    editGuestReferralId: guest && referralId,
                    editGuest: guest && JSON.parse(JSON.stringify(guest)),
                    guestReferralDifferences: guest && GUEST_FIELDS.reduce(function (result, key) {
                        result[key] = !(key === 'contactNumbers' && referral.contactNumber.split(/[,/]/).every(contactNumber => guest.contactNumbers.includes(contactNumber.trim()))
                            || key === 'dietaryRequirementsCore' && referral.dietaryRequirementsCore.every(dietaryRequirement => guest.dietaryRequirementsCore.includes(dietaryRequirement))
                            || key === 'languagesSpokenCore' && referral.languagesSpokenCore.every(language => guest.languagesSpokenCore.includes(language))
                            || key === 'ages' && guest.ages.length === referral.ages.length && referral.ages.every(age => guest.ages.includes(age)) && guest.ages.every(age => referral.ages.includes(age))
                            || (typeof guest[key] === 'string' || typeof referral[key] === 'string') && (guest[key] || '') === (referral[key] || '')
                            || guest[key] === referral[key])
                        return result
                    }, {})
                });
            })
    }

    getReferralActions(key) {
        const referral = this.props.referrals[key]["referral"];
        const addIcon = !referral.guest;
        const referralAgencyExists = this.props.getOptions('REFERRAL_AGENCY').some(opt => opt.value === referral.referralAgency)
        const acceptIcon = referral.guest && referral.guest.id && referral.status !== "ARCHIVED" && !referral.existingReferralWarning && referralAgencyExists;
        const archiveIcon = referral.guest && referral.guest.id && referral.status !== "ARCHIVED" && referralAgencyExists;
        const guestReferralDifferencesExist = this.state.guestReferralDifferences[key] && Object.values(this.state.guestReferralDifferences[key]).some(field => this.state.guestReferralDifferences[key][field])
        const defaultArchiveReason = referral?.existingReferralWarning?.includes('Food Bank') && 'DUPLICATE_RECEIVING_FOOD_PARCELS'
                                       || referral?.existingReferralWarning?.includes('Ready Meal') && 'DUPLICATE_RECEIVING_READY_MEALS'
                                       || 'OTHER'
        return (<GridContainer>
            {addIcon && (
                <GridItem md={2}>
                    <Tooltip title="Create new guest">
                        <IconButton aria-label="accept referral and create new guest" size="medium" onClick={() => this.props.addGuest(key)}>
                            <AddCircleOutlineRoundedIcon style={{ color: successColor[2] }}/>
                        </IconButton>
                    </Tooltip>
                </GridItem>)}
            {!addIcon && (
                <GridItem md={2}>
                    <Tooltip title="Open guest for editing">
                        <IconButton aria-label="edit guest" size="medium" onClick={() => this.openGuestForEditing(key)}>
                            <OpenInBrowser style={guestReferralDifferencesExist && { color: dangerColor[2] } || {}}/>
                        </IconButton>
                    </Tooltip>
                </GridItem>
            )}
            {acceptIcon && (
                <GridItem md={2}>
                    <Tooltip title="Accept referral">
                        <IconButton aria-label="accept referral" size="medium"
                                    onClick={() => this.props.acceptReferral(key)}>
                            <CheckCircleOutlineRoundedIcon style={{color: successColor[2]}}/>
                        </IconButton>
                    </Tooltip>
                </GridItem>)}
            {archiveIcon && (
                <GridItem md={2}>
                    <Tooltip title="Archive referral">
                        <IconButton aria-label="archive referral" size="medium"
                                    onClick={() => this.setState({confirmArchiveReferral: { id: key, archiveReason: defaultArchiveReason, archiveComment: '' }})}>
                            <ArchiveRoundedIcon/>
                        </IconButton>
                    </Tooltip>
                </GridItem>)}
            <GridItem md={2}>
                <Tooltip title="Change referral to Ready Meal referral">
                    <IconButton aria-label="switch referral" size="medium" onClick={() => this.setState({confirmSwitchToReadyMealReferralId: key})}>
                        <SyncAltIcon/>
                    </IconButton>
                </Tooltip>
            </GridItem>
            <GridItem md={2}>
                <Tooltip title="Edit referral">
                    <IconButton aria-label="expand row" size="medium" onClick={() => this.setEdit(key)}>
                        <EditOutlinedIcon/>
                    </IconButton>
                </Tooltip>
            </GridItem>
        </GridContainer>)
    }

    getGuestSelector(key) {
        const filterOptions = createFilterOptions({
          stringify: guest => `${guest.id}__${guest.fullName}__${guest.address}__${guest.postcode}__${guest.contactNumbers.toString()}`,
        });
        const referral = this.props.referrals[key]["referral"]
        const currentGuest = referral["guest"];
        const suggestedGuests = this.props.referrals[key]["suggestedGuests"];
        const guests = this.props.guests.filter(guest => suggestedGuests.includes(guest.id))
            .concat(this.props.guests.filter(guest => !suggestedGuests.includes(guest.id)))
        return (<React.Fragment>
                  <Autocomplete id={"select-guest-" + key}
                                value={currentGuest}
                                options={guests}
                                loading={this.props.guests.length === 0}
                                getOptionLabel={(guest) => guest.fullName}
                                style={{ width: 300 }}
                                renderOption={(guest) => <Typography>{`${guest.fullName}, ${guest.address}, ${guest.postcode}, ${guest.contactNumbers.toString()}`}</Typography>}
                                onChange={(event, newValue) => {this.setGuest(key, newValue.id)}}
                                groupBy={(guest) => (suggestedGuests && suggestedGuests.includes(guest.id)) ? "Suggested guests" : "All guests"}
                                getOptionSelected={(guest, value) => value?.id === guest?.id}
                                autoComplete
                                renderInput={(params) => <TextField {...params} label="Select guest" variant="outlined" />}
                                filterOptions={filterOptions} />
                  {currentGuest && referral.existingReferralWarning
                     && (<FormHelperText error={true}>{referral.existingReferralWarning}</FormHelperText>)}
                </React.Fragment>)
    }

    getModeOfDeliverySelector(key) {
        const referral = this.props.referrals[key]["referral"];
        return (<Select
          id={"select-modeOfDelivery-" + key}
          value={referral['modeOfDelivery']}
          onChange={(event) => this.props.setModeOfDelivery(key, event.target.value)}>
            <MenuItem value={'COLLECTION'}>Collection</MenuItem>
            <MenuItem value={'DELIVERY'}>Delivery</MenuItem>
        </Select>)
    }

    getCashPilotStatusSelector(key) {
        const referral = this.props.referrals[key]["referral"];
        return (
            <GridContainer>
                <GridItem md={12}>
                    <FormControlLabel
                      control={<Checkbox id="eligibleForCashPilot"
                                         name="eligibleForCashPilot"
                                         checked={referral['eligibleForCashPilot'] || false}
                                         onChange={ (event) => this.props.setEligibleForCashPilot(key, event.target.checked) } />}
                      label="Eligible" />
                </GridItem>
                {referral['eligibleForCashPilot'] && (<GridItem md={12}>
                    <FormControl fullWidth>
                        <InputLabel  shrink>Status</InputLabel>
                        <Select id={"select-cashPilotStatus-" + key}
                                value={referral['cashPilotStatus']}
                                onChange={(event) => this.props.setCashPilotStatus(key, event.target.value)}>
                            <MenuItem value={'NONE'}></MenuItem>
                            <MenuItem value={'CONTACTED_BY_TEXT'}>Contacted by text</MenuItem>
                            <MenuItem value={'CONTACTED_BY_PHONE'}>Contacted by phone</MenuItem>
                            <MenuItem value={'CONTACTED_FACE_TO_FACE'}>Contacted face to face</MenuItem>
                            <MenuItem value={'DECLINED'}>Declined</MenuItem>
                            <MenuItem value={'PARTICIPANT'}>Participant</MenuItem>
                        </Select>
                    </FormControl>
                </GridItem>)}
                {referral['cashPilotStatus'] === 'PARTICIPANT' && (<GridItem md={12}>
                    <FormControl fullWidth>
                        <InputLabel  shrink>Group</InputLabel>
                        <Select id={"select-cashPilotGroup-" + key}
                                value={referral['cashPilotGroup']}
                                onChange={(event) => this.props.setCashPilotGroup(key, event.target.value)}>
                            <MenuItem value={'CONTROL'}>Control</MenuItem>
                            <MenuItem value={'TREATMENT'}>Treatment</MenuItem>
                        </Select>
                    </FormControl>
                </GridItem>)}
                <GridItem md={12}>
                    <FormControl fullWidth>
                        <InputLabel shrink>Support type</InputLabel>
                        <Select id={"select-supportType-" + key}
                                value={referral['supportType']}
                                disabled={referral['cashPilotStatus'] !== 'PARTICIPANT' || referral['cashPilotGroup'] !== 'TREATMENT'}
                                onChange={(event) => this.props.setSupportType(key, event.target.value)}>
                            <MenuItem value={'FOOD_PARCEL'}>Food parcel</MenuItem>
                            <MenuItem value={'CASH_VOUCHER'}>Cash voucher</MenuItem>
                        </Select>
                    </FormControl>
                </GridItem>
            </GridContainer>
        )
    }

    getCollectionHubSelector(key) {
        const referral = this.props.referrals[key]["referral"];
        return (<Select
          id={"select-collectionHub-" + key}
          value={referral['collectionHub'] || 'Sufra'}
          onChange={(event) => this.props.setCollectionHub(key, event.target.value)}>
          {this.props.collectionHubs.filter(hub => hub.active).map(hub => (<MenuItem key={hub.name} value={hub.name}>{hub.name}</MenuItem>))}
        </Select>)
    }

    getGuestEditDialog() {
        const referral = this.props.referrals[this.state.editGuestReferralId].referral;
        const editGuest = this.state.editGuest;
        const guestReferralDifferences = this.state.guestReferralDifferences || {}
        const guestErrors = this.state.guestErrors || {}
        return (
            <GridContainer justify="center" alignItems="flex-start" spacing={10}>
                <GridItem md={5}>
                    <InputLabel shrink={true}>Referral full name</InputLabel>
                    <Typography>{referral.fullName}</Typography>
                </GridItem>
                <GridItem md={1}>
                    <Tooltip title="Use full name from latest referral">
                        <IconButton onClick={e => this.changeGuestValue("fullName", referral.fullName)}>
                            <ArrowForward/>
                        </IconButton>
                    </Tooltip>
                </GridItem>
                <GridItem md={5}>
                    <TextField id="guestFullName"
                               label="Guest full name"
                               InputLabelProps={{shrink: true,}}
                               fullWidth
                               error={guestReferralDifferences['fullName'] || guestErrors['fullName'] && guestErrors['fullName'].length > 0}
                               value={editGuest.fullName || ''}
                               onChange={ev => this.changeGuestValue("fullName", ev.target.value)}
                               helperText={guestReferralDifferences['fullName'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestErrors['fullName'] || ''}/>
                </GridItem>

                <GridItem md={5}>
                    <InputLabel shrink={true}>Referral address</InputLabel>
                    <Typography>{referral.address}</Typography>
                </GridItem>
                <GridItem md={1}>
                    <Tooltip title="Use address from latest referral">
                        <IconButton onClick={e => this.changeGuestValue("address", referral.address)}>
                            <ArrowForward/>
                        </IconButton>
                    </Tooltip>
                </GridItem>
                <GridItem md={5}>
                    <TextField id="guestAddress"
                               label="Guest address"
                               InputLabelProps={{shrink: true}}
                               margin="dense"
                               fullWidth
                               error={guestReferralDifferences['address'] || guestErrors['address'] && guestErrors['address'].length > 0}
                               value={editGuest.address || ''}
                               onChange={ev => this.changeGuestValue("address", ev.target.value)}
                               helperText={guestReferralDifferences['address'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestErrors['address'] || ''}/>
                </GridItem>

                <GridItem md={5}>
                    <InputLabel shrink={true}>Referral postcode</InputLabel>
                    <Typography>{referral.postcode}</Typography>
                </GridItem>
                <GridItem md={1}>
                    <Tooltip title="Use postcode from latest referral">
                        <IconButton onClick={e => this.changeGuestValue("postcode", referral.postcode)}>
                            <ArrowForward/>
                        </IconButton>
                    </Tooltip>
                </GridItem>
                <GridItem md={5}>
                    <TextField id="guestPostcode"
                               label="Guest postcode"
                               InputLabelProps={{shrink: true,}}
                               fullWidth
                               error={guestReferralDifferences['postcode'] || guestErrors['postcode'] && guestErrors['postcode'].length > 0}
                               value={editGuest.postcode || ''}
                               onChange={ev => this.changeGuestValue("postcode", ev.target.value)}
                               helperText={guestReferralDifferences['postcode'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestErrors['postcode'] || ''}/>
                </GridItem>

                <GridItem md={5}>
                    <InputLabel shrink={true}>Referral contact number</InputLabel>
                    <Typography>{referral.contactNumber}</Typography>
                </GridItem>
                <GridItem md={1}>
                    <Tooltip title="Add contact number(s) from referral">
                        <IconButton onClick={e => referral.contactNumber.split(/[,/]/).forEach(contactNumber => this.addGuestValueToList("contactNumbers", contactNumber.trim()))}>
                            <Add/>
                        </IconButton>
                    </Tooltip>
                </GridItem>
                <GridItem md={5}>
                    <InputLabel shrink={true}
                                error={guestReferralDifferences['contactNumbers'] || guestErrors['contactNumbers'] && guestErrors['contactNumbers'].some(error => error && error.length > 0)}>
                        Guest contact numbers
                    </InputLabel>
                    {editGuest.contactNumbers.map((contactNumber, idx) => {
                        return (<React.Fragment>
                            <div>
                                <TextField id={"guestContactNumber" + idx}
                                           value={contactNumber || ''}
                                           InputLabelProps={{shrink: true,}}
                                           error={guestErrors['contactNumbers'] && guestErrors['contactNumbers'][idx] && guestErrors['contactNumbers'][idx].length > 0}
                                           onChange={ev => this.changeGuestValueByIdx('contactNumbers', idx, ev.target.value)}
                                           helperText={guestErrors['contactNumbers'] && guestErrors['contactNumbers'][idx] || ''}/>
                                <IconButton aria-label="remove"
                                            size="small"
                                            onClick={() => this.deleteGuestValueByIdx('contactNumbers', idx)}>
                                    <DeleteIcon/>
                                </IconButton></div>
                            {guestReferralDifferences['contactNumbers'] && <FormHelperText error={true}>{GUEST_REFERRAL_DIFFERENCE_WARNING}</FormHelperText>}
                        </React.Fragment>)
                    })}
                    <IconButton aria-label="add"
                                size="small"
                                onClick={() => this.addGuestValueToList('contactNumbers', '')}>
                        <AddCircleOutlineOutlinedIcon/>
                    </IconButton>
                </GridItem>

                <GridItem md={5}>
                    <InputLabel shrink={true}>Referral disabled</InputLabel>
                    <Typography>{referral.disabled ? 'Yes' : 'No'}</Typography>
                </GridItem>
                <GridItem md={1}>
                    <Tooltip title="Use disabled from latest referral">
                        <IconButton onClick={e => this.changeGuestValue("disabled", referral.disabled)}>
                            <ArrowForward/>
                        </IconButton>
                    </Tooltip>
                </GridItem>
                <GridItem md={5}>
                    <InputLabel shrink={true} error={guestReferralDifferences['disabled']}>Guest disabled</InputLabel>
                    <Checkbox id="guestDisabled"
                              name="disabled"
                              checked={editGuest.disabled}
                              onChange={(ev) => this.changeGuestValue("disabled", ev.target.checked)}/>
                    {guestReferralDifferences['disabled'] && <FormHelperText error={true}>{GUEST_REFERRAL_DIFFERENCE_WARNING}</FormHelperText>}
                </GridItem>

                <GridItem md={5}>
                    <InputLabel shrink={true}>Referral disabled information</InputLabel>
                    <Typography>{referral.disabledInfo}</Typography>
                </GridItem>
                <GridItem md={1}>
                    <Tooltip title="Add disabled info from latest referral">
                        <IconButton onClick={e => this.changeGuestValue("disabledInfo", (editGuest.disabledInfo || '') + (referral.disabledInfo || ''))}>
                            <Add/>
                        </IconButton>
                    </Tooltip>
                </GridItem>
                <GridItem md={5}>
                    <TextField id="guestDisabledInfo"
                               label="Guest disabled information"
                               multiline
                               fullWidth
                               InputLabelProps={{shrink: true,}}
                               error={guestReferralDifferences['disabledInfo'] || guestErrors['disabledInfo'] && guestErrors['disabledInfo'].length > 0}
                               value={editGuest.disabledInfo || ''}
                               onChange={ev => this.changeGuestValue("disabledInfo", ev.target.value)}
                               helperText={guestReferralDifferences['disabledInfo'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestErrors['disabledInfo'] || ''}/>
                </GridItem>

                <GridItem md={5}>
                    <InputLabel shrink={true}>Referral accessibility issues</InputLabel>
                    <Typography>{referral.accessibilityIssues}</Typography>
                </GridItem>
                <GridItem md={1}>
                    <Tooltip title="Add accessibility issues from latest referral">
                        <IconButton onClick={e => this.changeGuestValue("accessibilityIssues", (editGuest.accessibilityIssues || '') + (referral.accessibilityIssues || ''))}>
                            <Add/>
                        </IconButton>
                    </Tooltip>
                </GridItem>
                <GridItem md={5}>
                    <TextField id="guestAccessibilityIssues"
                               label="Guest accessibility issues"
                               multiline
                               fullWidth
                               InputLabelProps={{shrink: true,}}
                               error={guestReferralDifferences['accessibilityIssues'] || guestErrors['accessibilityIssues'] && guestErrors['accessibilityIssues'].length > 0}
                               value={editGuest.accessibilityIssues || ''}
                               onChange={ev => this.changeGuestValue("accessibilityIssues", ev.target.value)}
                               helperText={guestReferralDifferences['accessibilityIssues'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestErrors['accessibilityIssues'] || ''}/>
                </GridItem>

                <GridItem md={5}>
                    <InputLabel shrink={true}>Referral gender</InputLabel>
                    <Typography>{referral.gender}</Typography>
                </GridItem>
                <GridItem md={1}>
                    <Tooltip title="Use gender from latest referral">
                        <IconButton onClick={e => this.changeGuestValue("gender", referral.gender)}>
                            <ArrowForward/>
                        </IconButton>
                    </Tooltip>
                </GridItem>
                <GridItem md={5}>
                    <InputLabel htmlFor="gender" shrink={true} error={guestReferralDifferences['gender']}>Gender</InputLabel>
                    <Select id="gender"
                            name="gender"
                            fullWidth
                            value={editGuest.gender || ''}
                            onChange={ev => this.changeGuestValue("gender", ev.target.value)}>
                        <MenuItem value="FEMALE">Female</MenuItem>
                        <MenuItem value="MALE">Male</MenuItem>
                        <MenuItem value="NON_BINARY">Non-Binary</MenuItem>
                        <MenuItem value="">Prefer not to say</MenuItem>
                    </Select>
                    {guestReferralDifferences['gender'] && <FormHelperText error={true}>{GUEST_REFERRAL_DIFFERENCE_WARNING}</FormHelperText>}
                </GridItem>

                <GridItem md={5}>
                    <InputLabel shrink={true}>Referral number of people</InputLabel>
                    <Typography>{referral.numberOfPeople}</Typography>
                </GridItem>
                <GridItem md={1}>
                    <Tooltip title="Use number of people from latest referral">
                        <IconButton onClick={e => this.changeGuestValue("numberOfPeople", referral.numberOfPeople)}>
                            <ArrowForward/>
                        </IconButton>
                    </Tooltip>
                </GridItem>
                <GridItem md={5}>
                    <TextField id="guestNumberOfPeople"
                               label="Guest number of people"
                               InputLabelProps={{shrink: true,}}
                               fullWidth
                               type="number"
                               error={guestReferralDifferences['numberOfPeople'] || guestErrors['numberOfPeople'] && guestErrors['numberOfPeople'].length > 0}
                               value={editGuest.numberOfPeople}
                               onChange={ev => this.changeGuestValue("numberOfPeople", ev.target.value)}
                               helperText={guestReferralDifferences['numberOfPeople'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestErrors['numberOfPeople'] || ''}/>
                </GridItem>

                <GridItem md={5}>
                    <InputLabel shrink={true}>Referral ethnicity</InputLabel>
                    <Typography>{referral.ethnicityCore + (referral.ethnicityOther && ` - ${referral.ethnicityOther}` || '')}</Typography>
                </GridItem>
                <GridItem md={1}>
                    <Tooltip title="Use ethnicity from latest referral">
                        <IconButton onClick={e => {
                            this.changeGuestValue("ethnicityCore", referral.ethnicityCore);
                            this.changeGuestValue("ethnicityOther", referral.ethnicityOther)
                        }}>
                            <ArrowForward/>
                        </IconButton>
                    </Tooltip>
                </GridItem>
                <GridItem md={5}>
                    <OptionWithOther coreField="ethnicityCore"
                                     coreLabel="Guest ethnicity"
                                     fullWidth
                                     id={'guest'}
                                     disabled={false}
                                     object={editGuest || {}}
                                     changeValue={(id, field, value) => this.changeGuestValue(field, value)}
                                     options={this.props.getOptions('ETHNICITY').map(option => option.value)}
                                     otherField="ethnicityOther"
                                     helperText={guestReferralDifferences['ethnicityCore'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestReferralDifferences['ethnicityOther'] && GUEST_REFERRAL_DIFFERENCE_WARNING || ''}/>
                </GridItem>

                <GridItem md={5}>
                    <InputLabel shrink={true}>Referral faith</InputLabel>
                    <Typography>{referral.faithCore + (referral.faithOther && ` - ${referral.faithOther}` || '')}</Typography>
                </GridItem>
                <GridItem md={1}>
                    <Tooltip title="Use faith from latest referral">
                        <IconButton onClick={e => {
                            this.changeGuestValue("faithCore", referral.faithCore);
                            this.changeGuestValue("faithOther", referral.faithOther)
                        }}>
                            <ArrowForward/>
                        </IconButton>
                    </Tooltip>
                </GridItem>
                <GridItem md={5}>
                    <OptionWithOther coreField="faithCore"
                                     coreLabel="Guest faith"
                                     fullWidth
                                     id={'guest'}
                                     disabled={false}
                                     object={editGuest || {}}
                                     changeValue={(id, field, value) => this.changeGuestValue(field, value)}
                                     options={this.props.getOptions('FAITH').map(option => option.value)}
                                     otherField="faithOther"
                                     helperText={guestReferralDifferences['faithCore'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestReferralDifferences['faithOther'] && GUEST_REFERRAL_DIFFERENCE_WARNING || ''}/>
                </GridItem>

                <GridItem md={5}>
                    <InputLabel shrink={true}>Referral DOB</InputLabel>
                    <Typography>{referral.dob}</Typography>
                </GridItem>
                <GridItem md={1}>
                    <Tooltip title="Use DOB from latest referral">
                        <IconButton onClick={e => this.changeGuestValue("dob", referral.dob)}>
                            <ArrowForward/>
                        </IconButton>
                    </Tooltip>
                </GridItem>
                <GridItem md={5}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                        <KeyboardDatePicker value={editGuest.dob && parseDate(editGuest.dob) || null}
                                            autoOk
                                            label="Guest DOB"
                                            variant="inline"
                                            onChange={(date) => this.changeGuestValue("dob", format(date, 'dd-MM-yyyy'))}
                                            format="dd-MM-yyyy"
                        />
                        {guestReferralDifferences['dob'] && <FormHelperText error={true}>{GUEST_REFERRAL_DIFFERENCE_WARNING}</FormHelperText>}
                    </MuiPickersUtilsProvider >
                </GridItem>

                <GridItem md={5}>
                    <InputLabel shrink={true}>Referral ages</InputLabel>
                    <Typography>{referral.ages.toString()}</Typography>
                </GridItem>
                <GridItem md={1}>
                    <Tooltip title="Use ages from latest referral">
                        <IconButton onClick={e => this.changeGuestValue("ages", referral.ages.slice())}>
                            <ArrowForward/>
                        </IconButton>
                    </Tooltip>
                </GridItem>
                <GridItem md={5}>
                    <ChipInput value={editGuest.ages || []}
                               label="Guest ages"
                               onBeforeAdd={(chip) => chip.match(/^\d+m?$/)}
                               onAdd={(age) => this.addGuestValueToList("ages", age)}
                               onDelete={(age) => this.deleteGuestValueByValue("ages", age)}
                               allowDuplicates={true} />
                    {guestReferralDifferences['ages'] && <FormHelperText error={true}>{GUEST_REFERRAL_DIFFERENCE_WARNING}</FormHelperText>}
                </GridItem>

                <GridItem md={5}>
                    <InputLabel shrink={true}>Referral dietary requirements</InputLabel>
                    <Typography>{referral.dietaryRequirementsCore.toString() + (referral.dietaryRequirementsOther && ` - ${referral.dietaryRequirementsOther}` || '')}</Typography>
                </GridItem>
                <GridItem md={1}>
                    <Tooltip title="Add dietary requirements from latest referral">
                        <IconButton onClick={e => {
                            this.changeGuestValue("dietaryRequirementsCore", editGuest.dietaryRequirementsCore.concat(referral.dietaryRequirementsCore));
                            this.changeGuestValue("dietaryRequirementsOther", (editGuest.dietaryRequirementsOther || '') + (referral.dietaryRequirementsOther || ''))
                        }}>
                            <Add/>
                        </IconButton>
                    </Tooltip>
                </GridItem>
                <GridItem md={5}>
                    <OptionWithOther coreField="dietaryRequirementsCore"
                                     coreLabel="Guest dietary requirements"
                                     fullWidth
                                     id={'guest'}
                                     disabled={false}
                                     multiple
                                     object={editGuest || {}}
                                     changeValue={(id, field, value) => this.changeGuestValue(field, value)}
                                     options={this.props.getOptions('DIETARY_REQUIREMENTS').map(option => option.value)}
                                     otherField="dietaryRequirementsOther"
                                     helperText={guestReferralDifferences['dietaryRequirementsCore'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestReferralDifferences['dietaryRequirementsOther'] && GUEST_REFERRAL_DIFFERENCE_WARNING || ''}/>
                </GridItem>

                <GridItem md={5}>
                    <InputLabel shrink={true}>Referral languages spoken</InputLabel>
                    <Typography>{referral.languagesSpokenCore.toString() + (referral.languagesSpokenOther && ` - ${referral.languagesSpokenOther}` || '')}</Typography>
                </GridItem>
                <GridItem md={1}>
                    <Tooltip title="Add languages spoekn from latest referral">
                        <IconButton onClick={e => {
                            this.changeGuestValue("languagesSpokenCore", editGuest.languagesSpokenCore.concat(referral.languagesSpokenCore));
                            this.changeGuestValue("languagesSpokenOther", (editGuest.languagesSpokenOther || '') + (referral.languagesSpokenOther || ''))
                        }}>
                            <Add/>
                        </IconButton>
                    </Tooltip>
                </GridItem>
                <GridItem md={5}>
                    <OptionWithOther coreField="languagesSpokenCore"
                                     coreLabel="Guest languages spoken"
                                     fullWidth
                                     id={'guest'}
                                     disabled={false}
                                     multiple
                                     object={editGuest || {}}
                                     changeValue={(id, field, value) => this.changeGuestValue(field, value)}
                                     options={this.props.getOptions('LANGUAGES_SPOKEN').map(option => option.value)}
                                     otherField="languagesSpokenOther"
                                     helperText={guestReferralDifferences['languagesSpokenCore'] && GUEST_REFERRAL_DIFFERENCE_WARNING || guestReferralDifferences['languagesSpokenOther'] && GUEST_REFERRAL_DIFFERENCE_WARNING || ''}/>
                </GridItem>
            </GridContainer>)
    }

    openGuestForEditing(referralId) {
        this.props.fetchReferral(referralId).then(() => {
            let referral = this.props.referrals[referralId].referral;
            let guest = referral.guest;
            this.setState({
                editGuestReferralId: referralId,
                editGuest: JSON.parse(JSON.stringify(guest)),
                guestReferralDifferences: GUEST_FIELDS.reduce(function (result, key) {
                    result[key] = !(key === 'contactNumbers' && referral.contactNumber.split(/[,/]/).every(contactNumber => guest.contactNumbers.includes(contactNumber.trim()))
                        || key === 'dietaryRequirementsCore' && referral.dietaryRequirementsCore.every(dietaryRequirement => guest.dietaryRequirementsCore.includes(dietaryRequirement))
                        || key === 'languagesSpokenCore' && referral.languagesSpokenCore.every(language => guest.languagesSpokenCore.includes(language))
                        || key === 'ages' && guest.ages.length === referral.ages.length && referral.ages.every(age => guest.ages.includes(age)) && guest.ages.every(age => referral.ages.includes(age))
                        || (typeof guest[key] === 'string' || typeof referral[key] === 'string') && (guest[key] || '') === (referral[key] || '')
                        || guest[key] === referral[key])
                    return result
                }, {})
            });
        });
    }

    changeGuestValue(field, value) {
        var editGuest = this.state.editGuest;
        var guestErrors = this.state.guestErrors || {};
        var guestReferralDifferences = this.state.guestReferralDifferences
        editGuest[field] = value;
        guestErrors[field] = (guestValidation[field] && guestValidation[field](value)) || ''
        guestReferralDifferences[field] = null;
        this.setState({editGuest: editGuest, guestErrors: guestErrors, guestReferralDifferences: guestReferralDifferences});
    }

    changeGuestValueByIdx(field, idx, value) {
        var editGuest = this.state.editGuest;
        var guestErrors = this.state.guestErrors || {};
        var guestReferralDifferences = this.state.guestReferralDifferences
        editGuest[field][idx] = value;
        guestErrors[field] = editGuest[field].map(fieldElement => guestValidation[field] && guestValidation[field](fieldElement) || '')
        guestReferralDifferences[field] = null;
        this.setState({editGuest: editGuest, guestErrors: guestErrors, guestReferralDifferences: guestReferralDifferences});
    }

    deleteGuestValueByIdx(field, idx) {
        var editGuest = this.state.editGuest;
        var guestErrors = this.state.guestErrors || {};
        var guestReferralDifferences = this.state.guestReferralDifferences
        editGuest[field].splice(idx, 1);
        guestErrors[field] = editGuest[field].map(fieldElement => guestValidation[field] && guestValidation[field](fieldElement) || '')
        guestReferralDifferences[field] = null;
        this.setState({editGuest: editGuest, guestErrors: guestErrors, guestReferralDifferences: guestReferralDifferences});
    }

    deleteGuestValueByValue(field, value) {
        var editGuest = this.state.editGuest;
        var guestErrors = this.state.guestErrors || {};
        var guestReferralDifferences = this.state.guestReferralDifferences
        editGuest[field].splice(editGuest[field].indexOf(value), 1);
        guestErrors[field] = editGuest[field].map(fieldElement => guestValidation[field] && guestValidation[field](fieldElement) || '')
        guestReferralDifferences[field] = null;
        this.setState({
            editGuest: editGuest,
            guestErrors: guestErrors,
            guestReferralDifferences: guestReferralDifferences
        });
    }

    addGuestValueToList(field, value) {
        var editGuest = this.state.editGuest;
        var guestErrors = this.state.guestErrors || {};
        var guestReferralDifferences = this.state.guestReferralDifferences
        editGuest[field].push(value);
        guestErrors[field] = editGuest[field].map(fieldElement => guestValidation[field] && guestValidation[field](fieldElement) || '')
        guestReferralDifferences[field] = null;
        this.setState({editGuest: editGuest, guestErrors: guestErrors, guestReferralDifferences: guestReferralDifferences});
    }

    editGuest() {
        if (!this.state.guestErrors
            || Object.keys(this.state.guestErrors)
                .every(field => !this.state.guestErrors[field]
                    || typeof this.state.guestErrors[field] === 'string' && this.state.guestErrors[field].length === 0
                    || Array.isArray(this.state.guestErrors[field]) && this.state.guestErrors[field].every(error => !error || error.length === 0))) {
            this.props.editGuest(this.state.editGuestReferralId, this.state.editGuest)
                .then(() => {
                    let guestReferralDifferences = this.state.guestReferralDifferences;
                    guestReferralDifferences[this.state.editGuestReferralId] = null;
                    this.setState({
                        editGuest: null,
                        guestErrors: null,
                        editGuestReferralId: null,
                        displayGuestMessage: false,
                        guestReferralDifferences: guestReferralDifferences
                    })
                })
        }
        else {
            this.setState({displayGuestMessage: true})
        }
    }

    confirmArchive() {
        this.props.archiveReferral(this.state.confirmArchiveReferral.id, this.state.confirmArchiveReferral.archiveReason, this.state.confirmArchiveReferral.archiveComment, new Date())
            .then(() => this.setState({ confirmArchiveReferral: null }));
    }

    confirmSwitchToReadyMealReferral() {
        this.props.switchToReadyMealReferral(this.state.confirmSwitchToReadyMealReferralId)
            .then(() => this.setState({ confirmSwitchToReadyMealReferralId: null }));
    }

    render() {
        const { classes } = this.props;
        const referralAgencyFilter = createFilterOptions();
        const cashPilotAdministrator = this.props.user && (this.props.user.roles.includes('ROLE_ADMIN') || this.props.user.roles.includes('ROLE_CASH_PILOT_ADMINISTRATOR'))
        return (
          <div className={classes.tableResponsive}>
            <Dialog open={this.state.confirmArchiveReferral}
                    onClose={() => this.setState({confirmArchiveReferral: null})}>
              <DialogTitle id="confirm-archive-title">{"Archive referral?"}</DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Are you sure you wish to archive referral {this.state.confirmArchiveReferral?.id} for {this.props.referrals[this.state.confirmArchiveReferral?.id]?.referral?.guest?.fullName}?
                  </DialogContentText>
                  <FormControl>
                      <InputLabel htmlFor="archiveReason">Archive reason</InputLabel>
                      <Select id="archiveReason"
                              name="archiveReason"
                              value={this.state.confirmArchiveReferral?.archiveReason || 'OTHER'}
                              onChange={ev => this.setState({ confirmArchiveReferral: { ...this.state.confirmArchiveReferral, archiveReason: ev.target.value} })}>
                          <MenuItem value="DUPLICATE_RECEIVING_FOOD_PARCELS">Duplicate - receiving food parcels</MenuItem>
                          <MenuItem value="DUPLICATE_RECEIVING_READY_MEALS">Duplicate - receiving ready meals</MenuItem>
                          <MenuItem value="GUEST_REQUESTED">Guest has requested for parcels to stop</MenuItem>
                          <MenuItem value="MOVED_AWAY">Guest has moved away</MenuItem>
                          <MenuItem value="OTHER">Other</MenuItem>
                      </Select>
                  </FormControl><br/>
                  <TextField id="archiveComment"
                             label="Archive Comment"
                             multiline
                             InputLabelProps={{shrink: true}}
                             value={this.state.confirmArchiveReferral?.archiveComment || ''}
                             onChange={ev => this.setState({ confirmArchiveReferral: { ...this.state.confirmArchiveReferral, archiveComment: ev.target.value} })}/>
              </DialogContent>
              <DialogActions>
                  <Button onClick={() => this.setState({confirmArchiveReferral: null})} color="primary">
                      Cancel
                  </Button>
                  <Button onClick={this.confirmArchive} color="primary" autoFocus>
                      Archive
                  </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={this.state.confirmSwitchToReadyMealReferralId} onClose={() => this.setState({confirmSwitchToReadyMealReferralId: null})}>
                <DialogTitle id="confirm-switch-referral-type-title">{"Switch to Ready Meal referral?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you wish to change referral {this.state.confirmSwitchToReadyMealReferralId} for {this.props.referrals[this.state.confirmSwitchToReadyMealReferralId]?.referral?.guest?.fullName} to a Ready Meal referral?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({confirmSwitchToReadyMealReferralId: null})} color="primary">
                        No
                    </Button>
                    <Button onClick={this.confirmSwitchToReadyMealReferral} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Dialog open={this.state.newReferralAgencyDialog}
                    onClose={() => this.setState({newReferralAgencyDialog: false})}>
                <DialogTitle id="confirm-archive-title">{"Create new referral agency"}</DialogTitle>
                <DialogContent>
                  <TextField id="newReferralAgency"
                             label="Referral Agency"
                             error={this.props.getOptions('REFERRAL_AGENCY').some(opt => opt.value === this.state.newReferralAgency)}
                             value={this.state.newReferralAgency || ''}
                             fullWidth
                             onChange={ev => this.setState({ newReferralAgency: ev.target.value })}
                             helperText={this.props.getOptions('REFERRAL_AGENCY').some(opt => opt.value === this.state.newReferralAgency)
                                           && "Referral agency already exists"}/>
                </DialogContent>
                <DialogActions>
                      <Button onClick={() => this.setState({
                          newReferralAgencyDialog: false,
                          newReferralAgency: null
                      })} color="primary">
                          Cancel
                      </Button>
                      <Button onClick={this.addNewReferralAgency} color="primary" autoFocus>
                          Save
                      </Button>
                </DialogActions>
            </Dialog>
              <Dialog open={this.state.editGuestReferralId}
                      onClose={() => this.setState({
                          editGuestReferralId: null,
                          editGuest: null,
                          guestErrors: null,
                          displayGuestMessage: false
                      })}
                      fullWidth
                      maxWidth="md">
                  <DialogTitle id="confirm-archive-title">{"Edit Guest"}</DialogTitle>
                  <DialogContent>
                      {this.state.editGuestReferralId && this.getGuestEditDialog()}
                  </DialogContent>
                  <DialogActions>
                      <Button onClick={() => this.setState({
                          editGuestReferralId: null,
                          editGuest: null,
                          guestErrors: null,
                          displayGuestMessage: false
                      })} color="primary">
                          Cancel
                      </Button>
                      <Button onClick={this.editGuest} color="primary" autoFocus>
                          Save
                      </Button>
                      {this.state.displayGuestMessage && (<FormHelperText error={true}>Please fix errors before saving</FormHelperText>)}
                  </DialogActions>
              </Dialog>
            <Table className={classes.table}>
              {this.props.tableHead !== undefined ? (
                <TableHead className={classes[this.props.tableHeaderColor + "TableHeader"]}>
                  <TableRow className={classes.tableHeadRow}>
                    <TableCell/>
                    {Object.keys(this.props.tableHead).map((prop, key) => {
                      return (
                        <TableCell
                          className={classes.tableCell + " " + classes.tableHeadCell}
                          key={key}
                        >
                          <TableSortLabel active={this.props.orderBy === prop}
                                          direction={this.props.orderBy === prop ? this.props.order : 'asc'}
                                          onClick={ev => {this.props.setOrderBy(prop)}}>
                            {this.props.tableHead[prop]}
                          </TableSortLabel>
                        </TableCell>
                      );
                    })}
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeadCell}
                      key={"modeOfDelivery"}
                    >
                      <TableSortLabel active={this.props.orderBy === "modeOfDelivery"}
                                      direction={this.props.orderBy === "modeOfDelivery" ? this.props.order : 'asc'}
                                      onClick={ev => {this.props.setOrderBy("modeOfDelivery")}}>
                        Mode of Delivery
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeadCell}
                      key={"collectionHub"}
                    >
                      <TableSortLabel active={this.props.orderBy === "collectionHub"}
                                      direction={this.props.orderBy === "collectionHub" ? this.props.order : 'asc'}
                                      onClick={ev => {this.props.setOrderBy("collectionHub")}}>
                        Collection hub
                      </TableSortLabel>
                    </TableCell>
                    <TableCell
                      className={classes.tableCell + " " + classes.tableHeadCell}
                      key={"guest"}
                    >
                      Guest
                    </TableCell>
                    {cashPilotAdministrator && (<TableCell
                          className={classes.tableCell + " " + classes.tableHeadCell}
                          key={"trial"}
                        >
                          Cash Pilot Status
                        </TableCell>)}
                    <TableCell/>
                  </TableRow>
                </TableHead>
              ) : null}
              <TableBody>
                {this.props.referralsOrder.map((id, idx) => {
                  const referral = this.props.referrals[id] && this.props.referrals[id].referral;
                  const editing = this.state.edit[id]
                  const editedReferral = this.state.edit[id] || referral
                  const errors = (editing && this.state.errors[id]) || {}
                  return referral && (
                    <React.Fragment>
                      <TableRow key={id} className={classes.tableBodyRowSummary}>
                        <TableCell className={classes.tableCellSummary}>
                          <Tooltip title="View referral">
                            <IconButton aria-label="expand row" size="small" onClick={() => this.setOpen(id)}>
                              {this.state.open[id] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                        { Object.keys(this.props.tableHead).map((prop, key) => {
                          return (
                            <TableCell className={classes.tableCellSummary} key={key}>
                              {this.props.mappingFunctions[prop](this.props.referrals[id])}
                              {prop === "contactNumber" && this.props.referrals[id].referral.permissionToContact
                                && (<Tooltip title="The guest has consented to be contacted with information about relevant courses and opportunities.">
                                        <PermPhoneMsgIcon style={{ color: infoColor[0], fontSize: "1rem" }}/>
                                    </Tooltip>)}
                              {prop === "referralAgency" && !this.props.getOptions('REFERRAL_AGENCY').some(opt => opt.value === referral.referralAgency)
                                && (<React.Fragment>
                                      <Tooltip title="Add new referral agency">
                                        <IconButton>
                                          <AddCircleOutlineOutlinedIcon style={{ color: successColor[0], fontSize: "1rem" }} onClick={() => this.setState({ newReferralAgencyDialog: true, newReferralAgency: referral.referralAgency })}/>
                                        </IconButton>
                                      </Tooltip>
                                      <FormHelperText error={true}>{"Invalid referral agency"}</FormHelperText>
                                    </React.Fragment>)}
                            </TableCell>
                          );
                        })}
                        <TableCell className={classes.tableCellSummary} key={"modeOfDelivery"}>
                          {this.getModeOfDeliverySelector(id)}
                        </TableCell>
                        <TableCell className={classes.tableCellSummary} key={"collectionHub"}>
                          {this.getCollectionHubSelector(id)}
                        </TableCell>
                        <TableCell className={classes.tableCellSummary} key={"guest"}>
                          {this.getGuestSelector(id)}
                        </TableCell>
                        {cashPilotAdministrator && (
                            <TableCell className={classes.tableCellSummary} key={"cashPilotStatus"}>
                              {this.getCashPilotStatusSelector(id)}
                            </TableCell>)}
                        <TableCell className={classes.tableCellSummary} key={"actions"}>
                          {this.getReferralActions(id)}
                        </TableCell>
                      </TableRow>
                      <TableRow key={id + "Details"} className={classes.tableBodyRowDetail}>
                        <TableCell className={classes.tableDetailsCell}/>
                        <TableCell className={classes.tableDetailsCell} colSpan={Object.keys(this.props.tableHead).length + 5 + (cashPilotAdministrator && 1 || 0)} key={"details"}>
                          <Collapse in={this.state.open[id]} timeout="auto" unmountOnExit>
                              <GridContainer>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <FormControl fullWidth>
                                          <Autocomplete id="referralAgency" name="referralAgency"
                                                        disabled={!editing}
                                                        value={editedReferral.referralAgency || ''}
                                                        onChange={(ev, newValue) => {
                                                          if (typeof newValue === 'string') {
                                                            this.setState({ newReferralAgencyDialog: true, newReferralAgency: newValue })
                                                          }
                                                          else if (newValue && newValue.inputValue) {
                                                            this.setState({ newReferralAgencyDialog: true, newReferralAgency: newValue.inputValue })
                                                          }
                                                          else {
                                                            this.changeValue(id, "referralAgency", newValue.value)}
                                                          }
                                                        }
                                                        filterOptions={(options, params) => {
                                                          const filtered = referralAgencyFilter(options, params);
                                                          if (params.inputValue !== '' && !filtered.some(opt => opt.value === params.inputValue)) {
                                                            filtered.push({
                                                              inputValue: params.inputValue,
                                                              value: `Add "${params.inputValue}"`,
                                                            });
                                                          }
                                                          return filtered;
                                                        }}
                                                        disableClearable
                                                        freeSolo
                                                        options={this.props.getOptions('REFERRAL_AGENCY')}
                                                        getOptionLabel={option => (typeof option === 'string') && option || option.inputValue || option.value}
                                                        renderOption={option => <li>{option.value}</li>}
                                                        renderInput={(params) => <TextField {...params} InputLabelProps={{shrink: true}} label="Referral Agency" />}/>
                                          {!this.props.getOptions('REFERRAL_AGENCY').some(opt => opt.value === referral.referralAgency) && <FormHelperText error={true}>Invalid referral agency</FormHelperText>}
                                      </FormControl>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <TextField id="nameOfAuthorisedSignatory"
                                                 label="Name of authorised signatory"
                                                 disabled={!editing}
                                                 error={errors['nameOfAuthorisedSignatory'] && errors['nameOfAuthorisedSignatory'].length > 0}
                                                 value={editedReferral.nameOfAuthorisedSignatory || ''}
                                                 onChange={ev => this.changeValue(id, "nameOfAuthorisedSignatory", ev.target.value)}
                                                 helperText={errors['nameOfAuthorisedSignatory'] || ''}/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <TextField id="emailOfAuthorisedSignatory"
                                                 label="Email of authorised signatory"
                                                 disabled={!editing}
                                                 error={errors['emailOfAuthorisedSignatory'] && errors['emailOfAuthorisedSignatory'].length > 0}
                                                 value={editedReferral.emailOfAuthorisedSignatory || ''}
                                                 onChange={ev => this.changeValue(id, "emailOfAuthorisedSignatory", ev.target.value)}
                                                 helperText={errors['emailOfAuthorisedSignatory'] || ''}/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}/>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <TextField id="reason"
                                                 label="Reason"
                                                 disabled={!editing}
                                                 multiline
                                                 fullWidth
                                                 error={errors['reason'] && errors['reason'].length > 0}
                                                 value={editedReferral.reason || ''}
                                                 onChange={ev => this.changeValue(id, "reason", ev.target.value)}
                                                 helperText={errors['reason'] || ''}/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <FormControl fullWidth>
                                          <InputLabel htmlFor="groupsMember">Groups</InputLabel>
                                          <Select id="groupsMember" name="groupsMember" multiple disabled={!editing} value={editedReferral.groupsMember || []} onChange={ev => this.changeValue(id, "groupsMember", ev.target.value)}>
                                              {this.props.getOptions('GROUPS').map(option => (<MenuItem value={option.value} disabled={!option.active}>{option.value}</MenuItem>))}
                                          </Select>
                                      </FormControl>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <TextField id="organisationsSupporting"
                                                 label="Referred or signposted to"
                                                 disabled={!editing}
                                                 multiline
                                                 fullWidth
                                                 error={errors['organisationsSupporting'] && errors['organisationsSupporting'].length > 0}
                                                 value={editedReferral.organisationsSupporting || ''}
                                                 onChange={ev => this.changeValue(id, "organisationsSupporting", ev.target.value)}
                                                 helperText={errors['organisationsSupporting'] || ''}/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <TextField id="furtherInfo"
                                                 label="Further info"
                                                 disabled={!editing}
                                                 multiline
                                                 fullWidth
                                                 error={errors['furtherInfo'] && errors['furtherInfo'].length > 0}
                                                 value={editedReferral.furtherInfo || ''}
                                                 onChange={ev => this.changeValue(id, "furtherInfo", ev.target.value)}
                                                 helperText={errors['furtherInfo'] || ''}/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <OptionWithOther coreField="nappiesCore"
                                                       coreLabel="Nappies"
                                                       id={id}
                                                       multiple
                                                       disabled={!editing}
                                                       object={editedReferral || {}}
                                                       changeValue={this.changeValue}
                                                       fullWidth
                                                       options={this.props.getOptions('NAPPIES').map(option => option.value)}
                                                       otherField="nappiesOther"/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <OptionWithOther coreField="babyMilkCore"
                                                       coreLabel="Baby milk"
                                                       id={id}
                                                       multiple
                                                       disabled={!editing}
                                                       object={editedReferral || {}}
                                                       changeValue={this.changeValue}
                                                       fullWidth
                                                       options={this.props.getOptions('BABY_MILK').map(option => option.value)}
                                                       otherField="babyMilkOther"/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <OptionWithOther coreField="toiletriesCore"
                                                       coreLabel="Toiletries"
                                                       id={id}
                                                       multiple
                                                       disabled={!editing}
                                                       object={editedReferral || {}}
                                                       changeValue={this.changeValue}
                                                       fullWidth
                                                       options={this.props.getOptions('TOILETRIES').map(option => option.value)}
                                                       otherField="toiletriesOther"/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}/>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <FormControlLabel
                                          control={<Checkbox id="permissionToContact" name="permissionToContact" disabled={!editing} checked={editedReferral.permissionToContact} onChange={ (ev) => this.changeValue(referral.id, "permissionToContact", ev.target.checked) } />}
                                          label="Permission to contact guest"
                                      />
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <FormControlLabel
                                          control={<Checkbox id="coronavirus" name="coronavirus" disabled={!editing} checked={editedReferral.coronavirus} onChange={ (ev) => this.changeValue(id, "coronavirus", ev.target.checked) } />}
                                          label="Coronavirus"
                                      />
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <FormControlLabel
                                          control={<Checkbox id="homelessOrTemporaryAccommodation" name="homelessOrTemporaryAccommodation" disabled={!editing} checked={editedReferral.homelessOrTemporaryAccommodation} onChange={ (ev) => this.changeValue(id, "homelessOrTemporaryAccommodation", ev.target.checked) } />}
                                          label="Homeless or temporary accommodation"
                                      />
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <FormControlLabel
                                          control={<Checkbox id="cookingFacilities" name="cookingFacilities" disabled={!editing} checked={editedReferral.cookingFacilities} onChange={ (ev) => this.changeValue(id, "cookingFacilities", ev.target.checked) } />}
                                          label="Cooking facilities"
                                      />
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <FormControl>
                                          <InputLabel htmlFor="modeOfDelivery">Mode of delivery</InputLabel>
                                          <Select id="modeOfDelivery" name="modeOfDelivery" disabled={!editing} value={editedReferral.modeOfDelivery || ""} onChange={ev => this.changeValue(id, "modeOfDelivery", ev.target.value)}>
                                              <MenuItem value="COLLECTION">Collection</MenuItem>
                                              <MenuItem value="DELIVERY">Delivery</MenuItem>
                                          </Select>
                                      </FormControl>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <TextField id="reasonForDelivery"
                                                 label="Reason for delivery"
                                                 disabled={!editing}
                                                 multiline
                                                 fullWidth
                                                 error={errors['reasonForDelivery'] && errors['reasonForDelivery'].length > 0}
                                                 value={editedReferral.reasonForDelivery || ''}
                                                 onChange={ev => this.changeValue(id, "reasonForDelivery", ev.target.value)}
                                                 helperText={errors['reasonForDelivery'] || ''}/>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <FormControl fullWidth>
                                          <InputLabel htmlFor="frequencyOfDelivery">Frequency of delivery</InputLabel>
                                          <Select id="frequencyOfDelivery" name="frequencyOfDelivery" disabled={!editing} value={editedReferral.frequencyOfDelivery || ""} onChange={ev => this.changeValue(id, "frequencyOfDelivery", ev.target.value)}>
                                              <MenuItem value="WEEKLY">Weekly</MenuItem>
                                              <MenuItem value="FORTNIGHTLY">Fortnightly</MenuItem>
                                          </Select>
                                      </FormControl>
                                  </GridItem>
                                  <GridItem xs={12} sm={12} md={3}>
                                      <TextField id="numberOfDeliveries"
                                                 label="Number of deliveries"
                                                 disabled={!editing}
                                                 value={editedReferral.numberOfDeliveries}
                                                 onChange={ev => this.changeValue(id, "numberOfDeliveries", ev.target.value)}
                                                 type="number"/>
                                  </GridItem>
                                  <GridItem xs={12}>
                                      <Button color="primary" disabled={!editing || Object.values(errors).some(err => err.length > 0)} onClick={e => this.editReferral(id)}>Update referral</Button>
                                  </GridItem>
                              </GridContainer>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        );
    }
}

export default withStyles(tableStyles, styles)(ReferralsTable);

ReferralsTable.defaultProps = {
  tableHeaderColor: "gray"
};

ReferralsTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.object
};
